<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Ikhtisar Klinik</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="result_tab">
                {{isParent.rowEdit.aranrd_date | moment('DD MMM YYYY, HH:mm')}} WIB
              </div>
            </td>
            <td>
              <pre class="pre-input">
              {{isParent.rowEdit.aranrd_ikhtisar||"-"}}
              </pre>
            </td>
          </tr>

        </tbody>
      </table>

    </div>
    
    <div class="col-md-12 mt-3 text-right">
      <button @click="doSave('DONE')" type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  methods: {
    doSave(isSetuju){
      let data = this.isParent.rowEdit
      data.aranrd_status = isSetuju
      data.type = 'verif-data'
      this.isParent.loadingOverlay = true
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = true
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.isParent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.isParent.openModal = false
              this.isParent.apiGet()
            })
        }).catch(err => {
            this.isParent.loadingOverlay = false
            if (err) {
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
            }
            this.isParent.doSetAlertForm(err)
        })
      })
    }
  },
}

</script>